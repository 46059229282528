import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Grid2 } from '@mui/material';
import { NavigationBar, NavigationBarHeight } from './NavigationBar/NavigationBar';
import { Routes } from './NavigationBar/Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

export function App() {
  return (
    <ThemeProvider theme={createTheme()}>
      <Router>
        <NavigationBar />
        <Grid2 container sx={{ marginTop: `${NavigationBarHeight}px`, minHeight: '100vh' }}>
          <Routes />
        </Grid2>
      </Router>
    </ThemeProvider>
  );
}
