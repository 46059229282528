import React from 'react';
import { Dashboard } from '../Dashboard/Dashboard';
import { Routes as RouterRoutes, Route } from 'react-router-dom';
import { Development } from '../Development/Development';

export enum ERoute {
  dashboard = 'dashboard',
  development = 'development',
}

export const AuthenticatedRoutes: ERoute[] = [
];


interface IRoute {
  name: ERoute;
  title: string;
  path: string;
  component: React.ReactNode;
}

export const RouteConfig: IRoute[] = [
  {
    name: ERoute.dashboard,
    path: '/dashboard',
    title: 'Dashboard - Strengths Identifier',
    component: <Dashboard />
  },
  {
    name: ERoute.development,
    path: '/development',
    title: 'Development - Strengths Identifier',
    component: <Development />
  },
];

function GetRoute(routeName: ERoute): IRoute {
  return RouteConfig.find((r) => r.name === routeName) as IRoute;
}

export const RouteMaster: {[x in ERoute]: IRoute} = {
  [ERoute.dashboard]: GetRoute(ERoute.dashboard),
  [ERoute.development]: GetRoute(ERoute.development),
};

export function Routes() {
  return (
    <RouterRoutes>
      {RouteConfig.map((route) => (
        <Route path={route.path} element={route.component} key={route.name} />
      ))}
    </RouterRoutes>
  );
}
