import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Text, IText} from './Text';
import { IColors } from './Colors';

interface ITextLink extends IText {
  path: string;
  children: React.ReactNode;
}

export function TextLink(props: ITextLink) {
  const { path, color, variant, children } = props;

  return (
    <Link sx={{ textDecoration: 'none' }} component={RouterLink} to={path}>
      <Text variant={variant ?? 'body1'} color={color ?? IColors.WHITE}>
        {children}
      </Text>
    </Link>
  );
}
