import { TypographyProps, Typography } from '@mui/material';
import { Colors, IColors } from './Colors';

export interface IText extends TypographyProps {
  color?: IColors;
  fontWeight?: number;
}

export function Text(props: IText) {
  return (
    <Typography {...props} sx={{ color: Colors[props.color ?? IColors.BLACK], fontWeight: props.fontWeight ?? 500, ...props.sx }} />
  );
}
